import api from '../store/API'

function cleanUpBroadcastItem(item) {
  const broadcastItem = {}
  if (item.item_type === 'mgp') {
    const { duration, items } = item.mgpBlock // Don't destructure id because it does not exist on new items
    broadcastItem.related_attributes = {
      id: item.mgpBlock.id,
      duration,
      items_attributes: items.map(({ id, selector_code, winner }) => ({
        id,
        selector_code,
        winner: !!winner,
      })),
    }
  } else if (item.item_type === 'poll' || item.item_type === 'music-poll') {
    const {
      title,
      content,
      authentication_required,
      answers,
      publish_percentage,
      image,
      hide_timeout_seconds,
      complete_message,
    } = item.poll // Don't destructure id because it does not exist on new items

    broadcastItem.related_attributes = {
      id: item.poll.id,
      title,
      content,
      hide_timeout_seconds,
      complete_message,
      authentication_required,
      publish_percentage,
      image: typeof image === 'string' ? null : image,
      answers_attributes: answers.map(({ id, body, image, fixed_winner, _destroy }) => {
        if (item.item_type === 'music-poll') {
          return { id, body, image_path: image, fixed_winner, _destroy }
        }
        return {
          id,
          body,
          image: typeof image === 'string' ? null : image,
          fixed_winner,
          _destroy,
        }
      }),
    }
  } else if (item.item_type === 'track') {
    // Don't override existing track code id
  }

  if (item.references) {
    broadcastItem.references_attributes = item.references.map(({ id, reference_id, reference_type, _destroy }) => ({
      id,
      reference_id,
      reference_type,
      _destroy,
    }))
  }

  return {
    ...broadcastItem,
    description: item.description,
    item_type: item.item_type,
    name: item.name,
    state: item.state,
    hour: item.hour,
  }
}

export async function fetchBroadcastItem(broadcastItemUrl) {
  return (await api.get(broadcastItemUrl)).data
}

export async function removeBroadcastItem(broadcastItemUrl) {
  await api.delete(broadcastItemUrl)
}

export async function liveUpdateBroadcastItem(broadcastItemUrl, body) {
  await api.post(`${broadcastItemUrl}/live_updates`, {
    body,
  })
}

export async function startEditing(broadcastItemUrl) {
  await api.get(`${broadcastItemUrl}/start_editing`)
}

export async function getBroadcastItemHtml(broadcastItemUrl) {
  return (await api.get(`${broadcastItemUrl}.js`)).data
}

export async function stopEditing(broadcastItemUrl) {
  await api.get(`${broadcastItemUrl}/stop_editing`)
}

export async function saveBroadcastItem(broadcastItemUrl, item) {
  return (
    await api.putForm(broadcastItemUrl, {
      broadcast_item: cleanUpBroadcastItem(item),
      inline_edit: true,
    })
  ).data
}

export async function createBroadcastItem(broadcastId, item, before, after) {
  return (
    await api.postForm(`/production/broadcasts/${broadcastId}/broadcast_items`, {
      broadcast_item: cleanUpBroadcastItem(item),
      'before-item-id': before?.getAttribute('data-id'),
      'before-item-type': before?.getAttribute('data-type'),
      'after-item-id': after?.getAttribute('data-id'),
      'after-item-type': after?.getAttribute('data-type'),
    })
  ).data
}
