<template>
  <div class="gif-picker">
    <div class="button" @click="togglePickerVisibility">
      <GIFPickerIcon />
    </div>
    <div :class="['gif-picker__modal', { visible: pickerVisible }]">
      <div v-if="!selectedGIF" class="gif-picker__content__search">
        <input v-model="query" type="text" placeholder="Zoek een GIF" @input="debouncedFetchGIFs" />
      </div>
      <n-infinite-scroll v-if="!selectedGIF" style="height: 300px" @load="fetchNextGIFs">
        <div class="gif-picker__content__gifs">
          <div v-for="gif in GIFs" :key="gif.id" class="gif" @click="selectGIF(gif)">
            <img :src="gif.url" />
          </div>
        </div>
      </n-infinite-scroll>
      <div v-else class="gif-picker__content__selected-gif">
        <img :src="selectedGIF.url" />
        <div class="meta">
          <div class="meta__title">{{ selectedGIF.title }}</div>
          <div data-role="tags">
            <span v-for="tag in selectedGIF.tags.split(',')" :key="tag">{{ tag }}</span>
          </div>
        </div>
        <div class="actions">
          <button @click="deselectGIF">Terug</button>
          <button class="actions__primary" @click="sendGIF">Verstuur</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from '@/store/API'
import { ref } from 'vue'
import { debounce } from '@/general/utils/debounce'
import GIFPickerIcon from '../../../assets/images/gifpicker.svg'
import { NInfiniteScroll } from 'naive-ui'

const emit = defineEmits(['send'])

const pickerVisible = ref(false)
const query = ref('')
const GIFs = ref([])
const selectedGIF = ref(null)

const togglePickerVisibility = () => {
  pickerVisible.value = !pickerVisible.value
  if (pickerVisible.value) {
    fetchGIFs()
  }
}

const page = ref(1)
const totalPages = ref(null)
const randomSeed = new Date().getTime()
const fetchGIFs = async () => {
  const { data } = await api.get('/production/shareable_gifs', {
    params: { term: query.value || null, page: page.value, seed: randomSeed },
  })
  GIFs.value = GIFs.value.concat(data.gifs)
  totalPages.value = data.pagination.totalPages
}
const fetchNextGIFs = async () => {
  if (page.value >= totalPages.value) {
    return
  }
  page.value++
  await fetchGIFs()
}
const searchGIFs = async () => {
  page.value = 1
  totalPages.value = null
  GIFs.value = []
  await fetchGIFs()
}

const debouncedFetchGIFs = debounce(searchGIFs, 200)

const selectGIF = (gif) => {
  selectedGIF.value = gif
}

const deselectGIF = () => {
  selectedGIF.value = null
}

const sendGIF = () => {
  pickerVisible.value = false
  emit('send', selectedGIF.value)
  selectedGIF.value = null
}
</script>

<style scoped lang="scss">
.gif-picker {
  .button {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
      fill: white;
    }
  }

  &__modal {
    position: absolute;
    bottom: 100%;
    right: 0;
    height: auto;
    width: 280px;
    background-color: white;
    box-shadow: 0 2px 10px #0003;
    border-radius: 10px;
    z-index: 20;
    transform: scale(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;

    &.visible {
      transform: scale(1);
    }
  }

  &__content__search {
    border-bottom: 1px solid #ccc;
    padding: 10px;

    input {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 2px;
      padding: 3px 5px;
    }
  }

  &__content__gifs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 10px;

    .gif {
      cursor: pointer;
      background: #f4f4f4;
      border-radius: 8px;
      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        transition: transform 0.2s;
      }
    }
  }

  &__content__selected-gif {
    padding: 10px;
    text-align: center;

    img {
      max-width: 100%;
      border-radius: 8px;
    }

    .meta {
      text-align: left;
      margin-top: 5px;

      &__title {
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    .actions {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      button {
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s;
        width: 100%;

        &:hover {
          background-color: #ddd;
        }

        &.actions__primary {
          background-color: #5ebd5e;
          color: white;

          &:hover {
            background-color: #4aa84a;
          }
        }
      }
    }
  }
}
</style>
