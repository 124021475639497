<template>
  <div v-if="recording.summary" class="recording">
    <div class="header">
      <button class="button__btn" @click.prevent="listen"><i class="fa fa-play" /></button>
      <strong>{{ startHour }}</strong>
    </div>
    <div v-html="summary" />
    <AudioPlayer v-if="openPlayer" ref="player" :url="recording.audio_url" />
  </div>
</template>

<script>
import { markdown } from '@/general/utils/markdown'
import { format } from 'date-fns'

import AudioPlayer from '@/general/components/AudioPlayer.vue'

export default {
  name: 'DoriaSummaryRecording',
  components: {
    AudioPlayer,
  },
  props: {
    recording: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openPlayer: false,
    }
  },
  computed: {
    summary() {
      return markdown(this.recording.summary)
    },
    startHour() {
      return format(new Date(this.recording.starts_at), "H'u'mm")
    },
  },
  methods: {
    listen() {
      this.openPlayer = true
      this.$nextTick(() => {
        this.$refs.player.play()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.recording {
  margin-top: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;

  .header {
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    .button__btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin-right: 6px;
      width: 18px;
      height: 18px;
      padding: 1em;

      font-size: 0.8em;

      background: #aaa;
      color: white;
      border: 0px;
      border-radius: 99999px;

      &:hover {
        background: #666666;
      }
    }
  }
}
</style>
