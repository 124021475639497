<template>
  <div class="message left">
    <div class="message-body">
      <form id="leave-comment-form" class="panel-footer chat-controls reply">
        <select id="reply_dj_id" v-model="replierId" class="form-control">
          <option value="">{{ currentStation().name }}</option>

          <optgroup label="Huidige djs">
            <option v-for="dj in currentDjs" :key="dj.id" :value="dj.id">{{ dj.internal_name }}</option>
          </optgroup>

          <optgroup label="Alle djs">
            <option v-for="dj in djs" :key="dj.id" :value="dj.id">{{ dj.internal_name }}</option>
          </optgroup>
        </select>
        <div class="chat-controls-input">
          <DarioExpandableTextArea
            ref="replyTextArea"
            v-model="reply"
            autoResize
            inputClass="form-control"
            rows="1"
            placeholder="Schrijf een antwoord"
          >
            <template #actions>
              <div class="action" @click="pickerVisible = !pickerVisible">
                <EmojiPickerIcon />
              </div>
              <DarioEmojiPicker v-if="pickerVisible" class="emoji-picker" @select="appendEmoji" />
            </template>
          </DarioExpandableTextArea>
        </div>
        <button class="btn btn-primary chat-controls-btn" name="button" type="submit" @click.prevent="addComment">
          Verstuur
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { mapGetters, mapState } from 'vuex'
import EmojiPickerIcon from '../../../assets/images/emojipicker.svg'
import DarioExpandableTextArea from '../../general/components/DarioExpandableTextArea.vue'

export default {
  name: 'ReplyForm',
  components: { DarioExpandableTextArea, EmojiPickerIcon },
  props: ['comment'],
  data() {
    return {
      replierId: '',
      reply: '',
      pickerVisible: false,
    }
  },
  computed: {
    ...mapGetters('Broadcasts', ['currentDjs']),
    ...mapState('Djs', ['djs']),
    repliers() {
      return [this.currentStation()].concat(this.djs)
    },
    replier() {
      return this.repliers.find((replier) => this.replierId === replier.id) || this.currentStation()
    },
  },
  methods: {
    currentStation() {
      return {
        name: window.userConfig.station.name.replace(' BE', '').replace(' NL', ''),
        id: '',
      }
    },
    appendEmoji(content) {
      this.$refs.replyTextArea.addContent(content.i)
    },
    addComment() {
      this.$store.dispatch('ContentComments/reply', {
        comment: this.comment,
        reply: {
          contentId: this.comment.contentId,
          from: {
            djId: this.replierId,
            name: this.replier.name,
          },
          commentedAt: new Date().toISOString(),
          text: this.reply,
        },
      })
      this.reply = ''
      nextTick(() => {
        window.$('.timeago').timeago()
      })
    },
  },
}
</script>

<style type="scss" scoped>
form {
  padding: 0;
}

.widget-chat .chat-controls #reply_dj_id {
  width: 145px;
}

.widget-chat .chat-controls .chat-controls-btn {
  right: 0px;
  width: 80px;
  top: 0px;
}
</style>
