<template>
  <ApiModal @close="$emit('close')">
    <template #header>
      <h4>
        <img class="doria-logo" src="../../assets/images/doria.png" />
        {{ broadcastedAt }} - {{ broadcast.title }}
      </h4>
    </template>
    <div class="panel-body">
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" />
        Samenvattingen laden…
      </div>
      <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
      <div v-for="(recordings, hour) in recordingsPerHour" :key="hour">
        <h4>{{ hour }}u00</h4>

        <DoriaSummaryRecording v-for="recording in recordings" :key="recording.id" :recording="recording" />
      </div>
    </div>
  </ApiModal>
</template>

<script>
import { format } from 'date-fns'
import nlDateLocale from 'date-fns/locale/nl'

import ApiModal from '@/general/components/ApiModal.vue'
import DoriaSummaryRecording from './DoriaSummaryRecording.vue'
import { airbrake } from '@/general/airbrake'

export default {
  name: 'DoriaSummaryPopup',
  components: {
    ApiModal,
    DoriaSummaryRecording,
  },
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      programRecordings: [],
      loading: true,
      errorMessage: null,
    }
  },
  computed: {
    recordingsPerHour() {
      return this.programRecordings.reduce((acc, programRecording) => {
        const hour = new Date(programRecording.starts_at).getHours()
        acc[hour] = acc[hour] || []
        acc[hour].push(programRecording)
        return acc
      }, {})
    },
    broadcastedAt() {
      return format(new Date(this.broadcast.starts_at), 'eeee dd MMM HH:mm', { locale: nlDateLocale })
    },
  },
  async mounted() {
    try {
      const response = await fetch(`/production/broadcasts/${this.broadcast.id}/program_recordings.json`)
      const { program_recordings } = await response.json()

      this.programRecordings = program_recordings
    } catch (error) {
      this.errorMessage = `Er is iets foutgegaan bij het ophalen van de samenvatting voor deze uitzending. Ververs de pagina en probeer het opnieuw. (Technische details: ${error.message})`
      airbrake?.notify(error)
    } finally {
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.doria-logo {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
</style>
