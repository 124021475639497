function createHTMLElement(htmlString) {
  const temp = document.createElement('div')
  temp.innerHTML = htmlString
  return temp.firstElementChild
}

const smartReplaceHTML = (originalHTML, newHTMLString, wholeReplace = false) => {
  let newElement

  if (wholeReplace) {
    newElement = createHTMLElement(newHTMLString)
  } else {
    newElement = originalHTML.cloneNode(false)
    newElement.innerHTML = newHTMLString
  }
  newElement.querySelectorAll('[data-component]').forEach((elem) => {
    const outerHTML = elem.outerHTML

    originalHTML.querySelectorAll(`[data-role=original-html]`).forEach((template) => {
      if (template.innerHTML === outerHTML) {
        elem.replaceWith(template.parentNode)
      }
    })
  })

  originalHTML.replaceWith(newElement)
}

window.smartReplaceHTML = smartReplaceHTML
