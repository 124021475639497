<template>
  <div class="player-container">
    <div v-if="showVideo" class="video">
      <button class="btn-fullscreen" @click="enterFullscreen"><i class="fa fa-expand" /></button>
      <video ref="video" :src="url" playsinline />
    </div>
    <div class="player">
      <div class="player__button">
        <button class="player__button__btn" @click="play">
          <i class="fa" :class="playing ? 'fa-pause' : 'fa-play'" />
        </button>
      </div>
      <div class="player__meta">
        <div v-if="monoChannel === 1" class="cover-channel-a" />
        <div v-if="monoChannel === 0" class="cover-channel-b" />
        <div ref="waveform" class="waveform" />
        <div class="player__meta__exports">
          <span class="time">
            <strong>{{ displayTime(currentTime) }}</strong> {{ displayTime(totalTime) }}
          </span>
          <button v-if="activeRegion" class="btn btn-with-icon btn-xs btn-primary" @click="clearRegions">
            <i class="fa fa-times" />
            {{ $t('production.audio_player.deselect') }}
          </button>
          <div>
            <slot />
            <button
              class="btn-xs btn btn-with-icon btn-default"
              :class="activeRegion && 'btn-warning'"
              @click="download"
            >
              <i class="fa fa-download" /> {{ downloadButtonTitle }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="stereo" class="player__channel_controls">
        <div v-for="channel in [0, 1]" :key="channel" class="player__channel_controls__container">
          <button
            class="btn btn-xs btn-default"
            :class="{ 'btn-warning': monoChannel === channel }"
            @click="toggleMonoChannel(channel)"
          >
            MONO
          </button>
        </div>
      </div>
    </div>
    <div class="transcription" data-role="transcription" v-text="transcription" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions'
import { audioBufferToWav, cutAudioBuffer, monoChannel, renderOfflineAudio } from '../utils/audio.js'

export default {
  props: {
    url: String,
    transcription: String,
    filename: String,
    showVideo: {
      type: Boolean,
      default: false,
    },
    stereo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playerId: crypto.randomUUID(),
      wavesurfer: null,
      regions: null,
      activeRegion: null,
      playing: false,
      totalTime: 0,
      currentTime: 0,
      monoChannel: null,
    }
  },
  computed: {
    downloadButtonTitle() {
      return this.activeRegion
        ? this.$t('production.audio_player.download_selection')
        : this.$t('production.audio_player.download')
    },
  },
  watch: {
    playing() {
      if (this.playing) {
        this.globallyMarkAsPlaying({ playerId: this.playerId, pauseFunction: () => this.wavesurfer.pause() })
      } else {
        this.globallyMarkAsStopped({ playerId: this.playerId })
      }
    },
  },
  mounted() {
    const media = this.showVideo ? this.$refs.video : new Audio(this.url)
    media.crossOrigin = 'anonymous'

    this.wavesurfer = WaveSurfer.create({
      container: this.$refs.waveform,
      height: 64,
      waveColor: '#433d3c',
      progressColor: '#f65d35',
      splitChannels: this.stereo,
      sampleRate: 48000,
      media,
    })

    this.wavesurfer.on('play', () => (this.playing = true))
    this.wavesurfer.on('pause', () => (this.playing = false))
    this.wavesurfer.on('ready', () => (this.totalTime = this.wavesurfer.getDuration()))

    this.regions = this.wavesurfer.registerPlugin(RegionsPlugin.create())
    this.regions.enableDragSelection({
      color: 'rgba(246, 93, 53, 0.1)',
    })
    this.regions.on('region-created', this.setActiveRegion)
    this.regions.on('region-updated', this.setActiveRegion)
    this.wavesurfer.on('timeupdate', (currentTime) => {
      this.currentTime = currentTime
      if (this.activeRegion && this.wavesurfer.isPlaying() && currentTime >= this.activeRegion.end) {
        this.wavesurfer.pause()
        this.setActiveRegion(this.activeRegion)
      }
    })
    this.stereo && media.addEventListener('play', () => this.adjustPlaybackForMonoChannel(media))
  },
  unmounted() {
    this.wavesurfer?.destroy()
  },
  methods: {
    ...mapActions({
      globallyMarkAsPlaying: 'AudioPlayers/markAsPlaying',
      globallyMarkAsStopped: 'AudioPlayers/markAsStopped',
    }),
    play() {
      this.wavesurfer.playPause()
    },
    displayTime(seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
    },
    setActiveRegion(region) {
      this.regions
        .getRegions()
        .filter((otherRegion) => otherRegion.element && otherRegion.id !== region.id)
        .forEach((otherRegion) => otherRegion.remove())

      this.activeRegion = region
      this.$emit('selectRegion', {
        start: region.start,
        end: region.end,
      })

      this.wavesurfer.seekTo(this.activeRegion.start / this.wavesurfer.getDuration())
    },
    clearRegions() {
      this.regions
        .getRegions()
        .filter((otherRegion) => otherRegion.element)
        .forEach((otherRegion) => otherRegion.remove())

      this.activeRegion = null
      this.$emit('selectRegion', null)

      this.wavesurfer.seekTo(0)
    },
    enterFullscreen() {
      const video = this.$refs.video
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen()
      }
    },
    toggleMonoChannel(channel) {
      if (this.monoChannel === channel) {
        this.monoChannel = null
      } else {
        this.monoChannel = channel
      }
      this.$emit('monoChannel', this.monoChannel)
      if (this.playing) {
        this.wavesurfer.pause()
        this.wavesurfer.play()
      }
    },
    adjustPlaybackForMonoChannel(media) {
      if (!this.audioContext) {
        this.audioContext = new AudioContext()

        this.audioContextSource = this.audioContext.createMediaElementSource(media)
      }
      this.audioContextSource.disconnect()

      if (this.monoChannel === null) {
        this.audioContextSource.connect(this.audioContext.destination)
        return
      }

      monoChannel(this.audioContext, this.audioContextSource, this.monoChannel).connect(this.audioContext.destination)
    },
    async download() {
      const audioBuffer = this.activeRegion
        ? cutAudioBuffer(this.wavesurfer.getDecodedData(), this.activeRegion.start * 1000, this.activeRegion.end * 1000)
        : this.wavesurfer.getDecodedData()

      const renderedBuffer = await renderOfflineAudio(audioBuffer, (context, source) => {
        if (this.monoChannel !== null) {
          return monoChannel(context, source, this.monoChannel)
        }
        return source
      })

      const blob = audioBufferToWav(renderedBuffer, 0, renderedBuffer.length)

      this.downloadBlob(blob)
    },
    downloadBlob(blob) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = this.filename
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },
  },
}
</script>

<style lang="scss" scoped>
.player-container {
  background: white;
  border-radius: 0.75em;
  padding: 0.75em 1em;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  .video {
    position: relative;
    height: 300px;
    margin-bottom: 1rem;
    background: #433d3c;
    border-radius: 0.25em;

    video {
      display: block;
      margin: 0 auto;
      height: 100%;
    }

    .btn-fullscreen {
      width: 2em;
      height: 2em;
      background: #f65d35;
      color: white;
      border-radius: 9999px;
      border: 0;
      position: absolute;
      z-index: 10;
      top: 0.5em;
      right: 0.5em;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

      &:hover {
        background: white;
        color: #f65d35;
        border: 1px solid #f65d35;
      }
    }
  }

  .player {
    display: flex;

    &__channel_controls {
      margin-left: 1em;

      &__container {
        height: 64px;
        display: flex;
        align-items: center;
      }
    }

    &__meta {
      flex: 1;
      margin-left: 1em;
      position: relative;

      &__exports {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.25em;

        .time {
          font-size: 0.8em;
          color: #433d3c;
        }

        div {
          display: flex;
        }
      }

      .cover-channel-a,
      .cover-channel-b {
        position: absolute;
        width: 100%;
        height: 64px;

        background: rgb(255 255 255 / 0.9);

        z-index: 10;
      }

      .cover-channel-b {
        top: 64px;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 22px;

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;

        // margin-top: 16px;
        width: 32px;
        height: 32px;
        padding: 1em;

        font-size: 1.2em;

        background: #f65d35;
        color: white;
        border: 3px solid #f65d35;
        border-radius: 99999px;
      }
    }
  }

  .alert {
    margin-top: 0.75em;
    margin-bottom: 0;
  }

  .transcription:not(:empty) {
    font-style: italic;
    margin-top: 0.75em;

    padding-left: 3.75em;
    padding-bottom: 0.25em;
  }
}
</style>
