<template>
  <div class="reply-container">
    <form @submit="submit">
      <div class="sender-select">
        <SenderSelect
          v-model="reply.sender"
          class="sender-select"
          :options="appSenders"
          :disabled="submitting"
          @change="changeSender"
        />
      </div>
      <div class="reply" :class="{ 'has-error': hasError }">
        <DarioExpandableTextArea
          ref="textArea"
          v-model="reply.content"
          class="textarea-container"
          :placeholder="$t('production.messages.reply.placeholder')"
          autoResize
          rows="1"
          :disabled="submitting"
          @keyup="toggleTyping"
          @keydown="handleSubmit"
          @update:modelValue="reply.ai_generated = false"
        >
          <template #actions>
            <div class="action" @click="pickerVisible = !pickerVisible">
              <EmojiPickerIcon />
            </div>
            <DarioEmojiPicker v-if="pickerVisible" class="emoji-picker" @select="addContent" />
            <DarioGIFPicker v-if="gifVisible" @send="sendGIF" />
          </template>
        </DarioExpandableTextArea>
        <button
          type="submit"
          :disabled="submitDisabled"
          :class="{ disabled: submitDisabled }"
          :title="$t('production.messages.reply.send_using_enter')"
        >
          <i v-if="!submitting" class="fa fa-send" />
          <i v-else class="fa fa-spinner fa-spin" />
        </button>
      </div>
    </form>
    <LlmReplySuggestion
      v-if="doriaEnabled"
      :messageId="messageId"
      :sender="reply.sender"
      @replySelected="aiReplySelected"
      @replySend="aiReplySend"
    />
  </div>
</template>

<script>
import DarioExpandableTextArea from '../general/components/DarioExpandableTextArea.vue'
import DarioEmojiPicker from '../general/components/DarioEmojiPicker.vue'
import api from '../store/API'
import SenderSelect from './SenderSelect.vue'
import _ from 'lodash'
import LlmReplySuggestion from './LlmReplySuggestion.vue'
import EmojiPickerIcon from '../../assets/images/emojipicker.svg'
import DarioGIFPicker from '../general/components/DarioGIFPicker.vue'

const STATES = {
  IDLE: 'idle',
  SUBMITTING: 'submitting',
  ERROR: 'error',
}

const LOCAL_STORAGE_SELECTED_SENDER = 'selected_sender'

export default {
  components: {
    LlmReplySuggestion,
    SenderSelect,
    DarioExpandableTextArea,
    EmojiPickerIcon,
    DarioEmojiPicker,
    DarioGIFPicker,
  },
  props: {
    messageId: { type: Number, required: true },
    appSenders: { type: Object, required: true },
    profile: { type: Object, required: true },
    doriaEnabled: { type: Boolean, required: true },
  },
  emits: ['replySent'],
  data() {
    let sender = null

    if (localStorage.getItem(LOCAL_STORAGE_SELECTED_SENDER)) {
      sender = this.appSenders.find((x) => x.id === localStorage.getItem(LOCAL_STORAGE_SELECTED_SENDER))
    }

    return {
      state: STATES.IDLE,
      pickerVisible: false,
      gifVisible: window.userConfig.features.gifs,

      reply: {
        sender: sender ?? this.appSenders[0],
        content: '',
        ai_generated: false,
      },
      startTypingThrottled: _.throttle(this.startTyping, 4000, {
        leading: true,
        trailing: false,
      }),
      stopTypingThrottled: _.throttle(this.stopTyping, 500, {
        leading: true,
        trailing: false,
      }),
      previousContent: null,
    }
  },

  computed: {
    submitting() {
      return this.state === STATES.SUBMITTING
    },

    hasError() {
      return this.state === STATES.ERROR
    },

    submitDisabled() {
      return this.submitting || this.reply.content === ''
    },
  },

  watch: {
    'reply.sender.id'(_, oldValue) {
      this.stopTyping(oldValue)
    },
  },

  mounted() {
    this.focusTextAreaNextTick()
  },

  beforeUnmount() {
    this.stopTyping()
  },

  methods: {
    focusTextAreaNextTick() {
      this.$nextTick(this.$refs.textArea.focus)
    },

    handleSubmit(e) {
      if (!(e.code === 'Enter' && (e.metaKey || e.ctrlKey))) {
        return
      }

      this.submit()
    },

    async submit(e = null) {
      if (this.submitDisabled) {
        return
      }

      e?.preventDefault?.()

      this.stopTyping()

      this.state = STATES.SUBMITTING
      try {
        const { data } = await api.post(
          `/production/messages/${this.messageId}/replies`,
          { reply: this.reply },
          {
            headers: {
              Accept: 'text/html',
            },
          }
        )

        this.$emit('replySent', data)

        this.$nextTick(() => {
          this.$nextTick(this.$refs.textArea.focus)
        })

        this.state = STATES.IDLE
        this.reply.content = ''
      } catch (error) {
        this.state = STATES.ERROR
        throw error
      }
    },
    async sendGIF(gif) {
      this.state = STATES.SUBMITTING
      try {
        const { data } = await api.post(
          `/production/messages/send_gif`,
          {
            gif_id: gif.id,
            profile_id: this.profile.id,
          },
          {
            headers: {
              Accept: 'text/html',
            },
          }
        )

        this.$emit('replySent', data)

        this.$nextTick(() => {
          this.$nextTick(this.$refs.textArea.focus)
        })

        this.state = STATES.IDLE
        this.reply.content = ''
      } catch (error) {
        this.state = STATES.ERROR
        throw error
      }
    },
    async toggleTyping() {
      if (this.reply.content === this.previousContent) {
        return
      }

      this.previousContent = this.reply.content

      if (this.reply.content) {
        return this.startTypingThrottled()
      }

      return this.stopTypingThrottled()
    },

    changeSender() {
      localStorage.setItem(LOCAL_STORAGE_SELECTED_SENDER, this.reply.sender.id)

      this.focusTextAreaNextTick()
    },

    aiReplySelected(reply) {
      this.reply.content = reply
      this.reply.ai_generated = true
    },

    aiReplySend(reply) {
      this.aiReplySelected(reply)
      this.submit()
    },

    async startTyping() {
      await api.put(`/production/messages/start_typing`, {
        sender_id: this.reply.sender.id,
        profile_id: this.profile.id,
      })
    },

    async stopTyping(dj_id = null) {
      await api.put(`/production/messages/stop_typing`, {
        sender_id: dj_id ?? this.reply.sender.id,
        profile_id: this.profile.id,
      })
    },

    addContent(content) {
      this.$refs.textArea.addContent(content.i)
    },
  },
}
</script>

<style lang="scss" scoped>
.reply-container {
  background-color: #443d3c;
  width: 100%;
  padding: 10px;

  > form {
    display: flex;

    .sender-select {
      margin-right: 15px;
      width: 242px;
    }

    .reply {
      display: flex;
      flex: 1;
      border: 1px solid #6a6463;
      border-radius: 5px;

      &.has-error {
        border-color: red;
      }

      .textarea-container {
        display: flex;
        flex: 1;
        padding-right: 5px;

        &:focus-within {
          box-shadow: inset 0 0 5px #6a6463;
        }

        :deep(textarea) {
          padding-left: 10px;
          padding-top: 6px;
          padding-bottom: 6px;
          width: 100%;
          border: 0;
          background-color: transparent;
          color: white;
          min-height: 32px;

          &:disabled {
            color: rgba(255, 255, 255, 0.8);
          }

          &::placeholder {
            color: #6a6463;
          }

          &:focus {
            outline: none !important;
          }
        }

        :deep(button) {
          img {
            filter: invert(1);
          }
        }
      }

      button {
        top: 1px;
        bottom: 1px;
        right: 1px;

        border: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        background-color: #6a6463;
        color: white;
        width: 35px;
        margin: 0;
        padding: 0;

        &.disabled {
          background-color: #8f8b89;
        }
      }
    }
  }
}
</style>
