<template>
  <a href="#" class="list-group-item" @click.prevent="open = true">
    <span class="badge"><i class="fa fa-external-link" /></span>
    <img class="doria-logo" src="../../assets/images/doria.png" />
    <span class="list-group-item-heading">{{ broadcastedAt }} - {{ broadcast.title }}</span>
  </a>
  <DoriaSummaryPopup v-if="open" :broadcast="broadcast" @close="open = false" />
</template>

<script>
import { format } from 'date-fns'
import nlDateLocale from 'date-fns/locale/nl'

import DoriaSummaryPopup from './DoriaSummaryPopup.vue'

export default {
  name: 'DoriaSummaryItem',
  components: {
    DoriaSummaryPopup,
  },
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    broadcastedAt() {
      return format(new Date(this.broadcast.starts_at), 'eee dd MMM HH:mm', { locale: nlDateLocale })
    },
  },
}
</script>

<style scoped>
.doria-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
