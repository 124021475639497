const $ = window.jQuery
class ChipInput extends HTMLElement {
  connectedCallback() {
    const name = this.getAttribute('name')
    const value = this.getAttribute('value') || ''
    this.classList.add('chip-input')

    this.innerHTML = `
      <input type="text" name="${name}" class="token-input" value="${value}">
    `

    const tokenInput = this.querySelector('.token-input')
    $(tokenInput).tokenField({ regex: /\S+/, delimiters: ',' })
  }
}

customElements.define('chip-input', ChipInput)
